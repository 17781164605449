.thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 33fr));
  gap: 0.3rem;
  width: 100vw;
  justify-items: center;
}

.thumbnail {
  width: 100%;
  aspect-ratio: 1 / 1; /* Ensures that the thumbnail is always a square */
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire thumbnail area */
}

.no-thumbnail {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
