.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 55%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  backdrop-filter: blur(9px);
}

.login-card {
  width: 100%;
  height: 56%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  padding-top: 40px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.4);
}

.Login .platefol-logo {
  width: 100%;
  text-align: center;
  font-family: "NewYork";
  font-size: 2.5rem;
  font-weight: 400;
  color: #263523;
  text-align: center;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.Login .login-title {
  font-family: "Poppins";
  font-size: 1.55rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.Login .login-subtitle {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.Login .login-with {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  margin-bottom: 20px;
  background-color: black;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
}

.google-logo {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
