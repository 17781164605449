.Modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 997;
  animation: fadeIn 0.5s ease;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  overflow: hidden;
  box-sizing: content-box;
}

.modal-content {
  width: 100%;
  max-height: 90%;
  min-height: 70%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  padding-bottom: 15px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  animation: flyIn 0.5s ease;
}

.modal-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  background-color: white;
}

.handle {
  width: 50px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  user-select: none;
}

.modal-header {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
  user-select: none;
}

.modal-body {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Class for hiding modal that flys out of the bottom */
.hide-modal {
  transform: translateY(100%);
  transition: 300ms linear all;
  transition-delay: 0ms;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-direction: reverse;
}

@keyframes flyIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(6px);
  }
}
