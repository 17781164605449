/* Comments.css */

.Comments {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  height: 400px;
  flex-grow: 1;
  overflow-y: scroll;
  scrollbar-width: none; /* Hide scrollbar */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.Comments::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.comment-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  padding-bottom: 150px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.comment-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.no-comments {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-comments p:nth-of-type(1) {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
}

.Comments .comment {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
}

.Comments .comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.75px solid #ccc;
  object-fit: cover;
  margin-right: 0.75rem;
}

.Comments .comment-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Comments .comment-author {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.Comments .comment-content {
  font-size: 0.9rem;
}

.Comments .comment-actions {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding-block: 0.5rem;
  box-sizing: content-box;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.1);
}

.Comments .comment-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.Comments .comment-input {
  width: 100%;
  height: 100%;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  font-size: 1rem;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
}

.Comments .comment-submit {
  width: 50px;
  height: 35px;
  border-radius: 20px;
  background-color: #566b53;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
}

.comment-submit .submit-arrow {
  height: 20px;
}

.read-more,
.read-less {
  color: gray;
  cursor: pointer;
  margin-left: 5px;
}

.read-more:hover,
.read-less:hover {
  text-decoration: underline;
}

/* Add this to your CSS file */
.comment-time {
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.35);
  margin-left: 0.2rem;
  flex-basis: 0;
  flex-grow: 1;
}

.comment-options-icon {
  height: 4px;
  object-fit: cover;
  cursor: pointer;
  top: 0;
  right: 0;
  opacity: 0.5;
}

.comment-options-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  right: 10px;
  z-index: 1;
}

.comment-option {
  padding: 10px;
  cursor: pointer;
}

.comment-option:hover {
  background-color: #f0f0f0;
}

.hashtag {
  color: #566b53;
  cursor: pointer;
  font-weight: bold;
}
