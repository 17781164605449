.Toolbar {
  background-color: white;

  width: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
}

.ActiveToolbar {
  background: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.logo-section {
  height: 90px;
  flex-grow: 1;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.logo-section div {
  /* Create Ellipsis on Nowrap */
  font-size: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.logo-section {
  height: 90px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-basis: 0;
  flex-grow: 4;

  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.section-bar {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  padding-inline: 30px;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.section-bar::-webkit-scrollbar {
  display: none;
}

.section {
  padding-inline: 25px;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  scroll-snap-align: start;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 2px;
  box-sizing: content-box;
  flex-basis: 0;
  flex-grow: 1;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.active-section-highlight {
  font-family: "Poppins";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.action-button {
  position: relative;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  user-select: none;
}

.action-button .icon {
  /* position: relative; */
  height: 45%;
  transition: 60ms linear all;
  user-select: none;
}

.action-button:active .icon {
  height: 55%;
  transition: 60ms linear all;
}

.back-icon {
  fill: black;
}

.logo {
  height: 80%;
  width: 100%;
  object-fit: contain;
}

.ActiveToolbar .logo {
  filter: brightness(1000);
}

.toolbar-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.grid-icon {
  height: 18px;
  width: 18px;
}

.handshake-icon {
  height: 35px;
  opacity: 0.5;
}
