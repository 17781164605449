.user-profile {
  padding-bottom: 75px;
}

.user-profile-hero {
  padding-top: 15px;
}

.user-profile-hero .user-info {
  text-align: center;
}

.user-profile-hero .user-info .profile-pic {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}

.users-name {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: "Poppins";
  font-weight: 500;
}

.friend-count {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0px;
  padding: 0px;
  font-family: "Poppins";
  font-weight: 400;
}

.friend-count .count {
  font-weight: 600;
}

.content-carousel {
  margin-top: 5px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);

  /* Set Up Scroll Snap */
  scroll-snap-type: x mandatory;
}

.liked-items {
  min-width: 100%;

  /* Set Up Scroll Snap */
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.post-items {
  min-width: 100%;

  min-height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding-top: 50px;
}

.user-scrolling-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: white;
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user-scrolling-toolbar.hidden {
  transform: translateY(-100px);
  transition: transform 0.3s;
}

.user-scrolling-toolbar .profile-pic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}

.user-scrolling-toolbar .user-info {
  margin-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
}

.user-scrolling-toolbar .users-name {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.toggle-toolbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-carousel-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
}

.content-carousel-toggle .toggle-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.toggle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  padding: 10px;
  transition: 0.2s linear all;
}

.toggle-icon.active {
  font-weight: 600;
  color: #263523;
  transition: 0.2s linear all;
}

.toggle-slider {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.toggle-slider .slider-container {
  width: 50%;
  height: 5px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(0%);
  transition: linear 0.2s transform;
}

.slider-container.active {
  transform: translateX(100%);
  transition: linear 0.2s transform;
}

.slider-container .slider {
  width: 85%;
  height: 100%;
  background-color: black;
  border-radius: 5px;
  position: absolute;
}

.coming-soon {
  width: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.profile-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-inline: 25px;
}

.profile-actions .logo {
  font-size: 1.8rem;
  font-weight: 400;
  color: #263523;
}

.logout-button {
  color: red;
  font-weight: 600;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-top: 10px;
}
