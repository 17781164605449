@keyframes bounceSignal {
  0% {
    position: absolute;
    top: 0px;
  }
  50% {
    position: absolute;
    filter: brightness(0.5) saturate(1.2);
    top: -80px;
  }
  100% {
    position: absolute;

    top: 0px;
  }
}

.ScrollMenu {
  width: 100%;
  flex-grow: 1;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  margin-bottom: 0; /* Default margin */
  transition: margin-bottom 0.2s ease-in-out;
}

.ScrollMenu.with-bottom-toolbar {
  margin-bottom: 70px; /* Height of the bottom toolbar */
}

.ScrollMenu::-webkit-scrollbar {
  display: none;
}

.follow-button {
  margin-left: 8px;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: white;
  padding: 2px 10px;
  border-radius: 5px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  transition: 120ms linear all;
}

.follow-button:active {
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
  transform: scale(0.95);
}

.following {
  border: 1.5px solid rgba(255, 255, 255, 0.6);
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 30px;
  margin-top: 15px;
}

.primary-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.secondary-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
}

.upload-action {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 20px;
}

.action-buttons .button-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.action-buttons .action-button {
  border: none;
  border-radius: 10px;
  height: 50px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 200ms linear all;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}

.menu-button {
  background-color: white;
  margin-left: 10px;
  margin-right: 5px;
  color: #566b53;
  opacity: 0.7;
}

.menu-button:active {
  opacity: 1;
}

.map-button {
  background: #566b53;
  color: white;
  margin-left: 5px;
  margin-right: 10px;
}

.map-button:active {
  background: #3f4d3a;
}

.upload-button {
  outline: 2px solid white;
  color: white;
  margin-inline: 10px;
  box-sizing: border-box;
  transition: 150ms linear all;
}

.upload-button.saved {
  background: #566b53;
  outline: none;
}

.away {
  position: absolute;
  left: 100px;
  transform: rotate(30deg);
}

.bounce-signal {
  animation-name: bounceSignal;
  animation-duration: 1s;
  animation-direction: forwards;
  animation-delay: 1000ms;
  animation-iteration-count: 2;
}

.scrollItem {
  position: relative;
  overflow: hidden;

  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  user-select: none;
}

.scrollItem .gradient {
  width: 100%;
  height: 100%;
  border-radius: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  background: linear-gradient(
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0) 40%,

    rgba(0, 0, 0, 1) 100%
  );
  z-index: 2;
}

.expanded-gradient {
  filter: brightness(0.2);
  border-radius: 0;
  overflow: hidden;

  transition: 200ms linear filter;
}

.expanded-gradient + .gradient {
  backdrop-filter: blur(4px);
  transition: 100ms linear backdrop-filter;
}

.scrollItem .item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  transition: 200ms linear all;
  overflow: hidden;
  object-position: 50% 50%;
  z-index: 1;
  user-select: none;
}

.item-image video {
  user-select: none;
  object-fit: cover;
}

.item-image img {
  user-select: none;
}

.item-image.loading {
  filter: blur(5px);
  transition: 300ms linear all;
  height: 100%;
  width: 100%;
}

.ken-burns {
  animation-name: ken-burns;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.scrollItem .item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding-inline: 15px;
  box-sizing: border-box;
}

.scrollItem .item-text {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  bottom: 10px;
  left: 0;
  padding-bottom: 5px;
  padding-right: 5px;
  z-index: 3;
  user-select: none;
  opacity: 1;
  transition: 200ms linear all;
}

.scrollItem .item-text .item-price-container {
  color: white;
  font-family: "Poppins";
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.item-price {
  margin-right: 5px;
}

span.restaurant-tag {
  /* Set Ellipsis and Prevent Overflow Wrapping */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
}

.scrollItem .item-text .item-title {
  color: white;
  font-size: 25px;
  user-select: none;
  z-index: 3;
}

.scrollItem .item-text .item-description {
  color: white;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 15px;
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  transition: 200ms linear all;
  user-select: none;
  z-index: 3;
}

.scrollItem:active .item-text {
  animation-name: hideText;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-delay: 200ms;
  transition: 200ms linear all;
}

.scrollItem .no-content-background {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
}

.interaction-toolbar {
  min-width: 60px;
}

.test-option {
  width: 55px;
  height: 55px;
}

.no-content {
  width: 100%;
  height: 100%;
}

.no-content-prompt {
  color: white;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  text-align: center;
  width: 80%;
  z-index: 10;
}

.oops {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
}

.upload-description {
  color: white;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 15px;
  user-select: none;
  margin-bottom: 20px;
}

@keyframes hideText {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.collapsed {
  max-height: 60px;
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  transition: 200ms linear all;
}

.expanded {
  max-height: 500px;
  overflow: scroll;
  transition: 200ms linear all;

  mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
}

.scrollItem .item-content .item-allergens {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-block: 5px;
  user-select: none;
  justify-self: flex-start;
}

.allergens-heading {
  color: white;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
}

.allergens {
  display: flex;
}

.allergen {
  color: white;
  font-weight: 200;
  font-size: 15px;
  padding-left: 5px;
}

.restaurant-name {
  color: white;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  user-select: none;
}

.interaction-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  box-sizing: content-box;
}

.icon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* Make the overlay transparent */
  z-index: 1000;
}

.interaction-icon {
  width: 32px;
  object-fit: cover;
  opacity: 1;
  background: none;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  box-sizing: content-box;
  z-index: 999;
}

.interaction-count {
  margin-top: 5px;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  width: 100%;
}

.interaction-icon#share-icon {
  width: 26px;
}

.interaction-icon#kebab-icon {
  width: 18px;
}

.liked {
  animation-name: liked;
  animation-duration: 700ms;
  animation-fill-mode: forwards;
}

.verified-badge {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}

@keyframes liked {
  0% {
    filter: brightness(30);
    transform: scale(1.25);
  }
  100% {
    filter: brightness(1);
    transform: scale(1);
  }
}

@keyframes ken-burns {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.15) translate(0, 0);
  }
}
