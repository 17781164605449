/* BottomToolbar.css */
.bottom-toolbar {
  position: fixed;
  bottom: -80px; /* Start hidden below the viewport */
  left: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  backdrop-filter: blur(3px);
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: bottom 0.2s ease-in-out;
  z-index: 995;
}

.bottom-toolbar.dark {
  background-color: rgb(0, 0, 0);
  border-top: 0.5px solid rgba(256, 256, 256, 0.3);
}

.bottom-toolbar.show {
  bottom: 0; /* Slide up into view */
}

.bottom-toolbar-item {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.bottom-toolbar-item.dark {
  color: rgba(256, 256, 256, 0.7);
}

.bottom-toolbar-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.bottom-toolbar-icon {
  width: 20px;
  opacity: 0.5;
}

.bottom-toolbar-item-text {
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  opacity: 0.6;
}

.bottom-toolbar-item-text.dark {
  color: rgba(256, 256, 256, 1);
}

.active {
  opacity: 1;
  font-weight: 500;
}
