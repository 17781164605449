.platefol {
  font-family: "NewYork";
}

.jost {
  font-family: "Jost";
}

.bebas-neue {
  font-family: "Bebas Neue";
}

.courier-new {
  font-family: "Courier New";
}

.caesar-dressing {
  font-family: "Caesar Dressing";
}

.eb-garamond {
  font-family: "EB Garamond";
}

.florence {
  font-family: "Florence";
}

.gluten {
  font-family: "Gluten";
}

.knewave {
  font-family: "Knewave";
}

.laviossa {
  font-family: "Laviossa";
}

.league-spartan {
  font-family: "League Spartan";
}

.lilita-one {
  font-family: "Lilita One";
}

.josefin-sans {
  font-family: "Josefin Sans";
}

.rakkas {
  font-family: "Rakkas";
}

.spicy-rice {
  font-family: "Spicy Rice";
}

.shrikhand {
  font-family: "Shrikhand";
}

.rubik {
  font-family: "Rubik";
}

.rye {
  font-family: "Rye";
}

.reenie-beanie {
  font-family: "Reenie Beanie";
}

.playball {
  font-family: "Playball";
}

.bevan {
  font-family: "Bevan";
}

.cherry-bomb {
  font-family: "Cherry Bomb";
}

.cutive {
  font-family: "Cutive";
}

.eagle-lake {
  font-family: "Eagle Lake";
}

.future {
  font-family: "Futura";
}

.galindo {
  font-family: "Galindo";
}

.lato {
  font-family: "Lato";
}

.libre-bodoni {
  font-family: "Libre Bodoni";
}

.limelight {
  font-family: "Limelight";
}

.mochiy-pop-p-one {
  font-family: "Mochiy Pop P One";
}

.molle {
  font-family: "Molle";
}

.rockwell {
  font-family: "Rockwell";
}

.newsreader {
  font-family: "Newsreader";
}

.pavanam {
  font-family: "Pavanam";
}

.pridi {
  font-family: "pridi";
}
